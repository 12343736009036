import axios from "axios";
import { HttpInnerResponse } from "../models/HttpInnerResponse";
import { Importeduser } from "../models/ImportedUser";
import { IAutocompleteUser } from "../models/AutocompleteUser";
import { FileUtilService, ISheetData } from "./util/FileUtilService";
import { Endpoints } from '../config/endpoints';
import { ICustomer } from '../models/Customer'
import { IUser } from "../models/User";
import { IAccessLevel } from "../models/AccessLevel";
import { EncryptHelper } from "helpers/EncryptHelper";
import { IUserPasswordValidationsResponse } from "models/UserPasswordValidations";

interface IUsersService {
  getUsers(query: string): Promise<IUser[]>;
  getUsersByTenant(tenantId: string): Promise<IUser[]>;
  getAppUsersByRegion(regionId: number): Promise<any[]>;
  getUserById(userId: string): Promise<Pick<IAutocompleteUser, 'id' | 'code'>>;
  getAllUsers(): Promise<IUser[]>;
  getAccessLevels(): Promise<IAccessLevel[]>;
  createUsersUsingFile(file: File): Promise<any>;
  getCustomers(query?: string): Promise<ICustomer[]>;
  createUser(user: IUser): Promise<void>;
  saveUser(user: IUser): Promise<void>;
  updateUserFields(userId: string, fields: Object): Promise<void>;
  deleteUser(user: IUser): Promise<void>;
  blockUser(userName: string): Promise<any>;
  activateUser(userId: string): Promise<void>;
  getUserPasswordValidations(userId: string): Promise<IUserPasswordValidationsResponse>;
  getAppUsers(params: { id: string }): Promise<IUser[]>;
  getUserPreferences(userId: string): Promise<any[]>;
  updateUserPreferences(userId: string, preferences: Object): Promise<void>;
}


const userEndpoint = `${Endpoints.getEndpoint("REACT_APP_IDENTITY_ENDPOINT")}user/`;
const userPreferencesEndpoint = `${Endpoints.getEndpoint("REACT_APP_IDENTITY_ENDPOINT")}preferences/`;
const appUserEndpoint = `${Endpoints.getEndpoint("REACT_APP_IDENTITY_ENDPOINT")}userApp/`;
const identityEndpoint = `${Endpoints.getEndpoint("REACT_APP_IDENTITY_ENDPOINT")}`;

export const UsersService: IUsersService = {  
  async createUsersUsingFile(file: File): Promise<any> {
    const { data }: ISheetData = await FileUtilService.readExcelFile(file);
    const promises: Promise<{ status: string; data: any }>[] = data.map(
      async (row: Importeduser) =>
        await new Promise(async (resolve, reject) => {
          try {
            const { data } = await axios.post<HttpInnerResponse>(`${userEndpoint}/add`, row);
            resolve({ status: "resolved", data });
          } catch (e) {
            reject({ status: "rejected", data });
          }
        })
    );
    return promises;
  },

  getUsers: async (criteria: string): Promise<IUser[]> => {
    // TODO: Update according to real service query params syntax
    const params = {
      q: criteria,
    };
    const url = `${Endpoints.getEndpoint("REACT_APP_IDENTITY_ENDPOINT")}users`;
    const { data } = await axios.post(url, { params });
    return data;
  },

  getUsersByTenant: async (tenantId: string): Promise<IUser[]> => {
    const url = `${userEndpoint}tenant/${tenantId}`;
    const { data } = await axios.get(`${url}`);
    return Promise.resolve(data);
  },

  getAppUsersByRegion: async (regionId: number): Promise<IUser[]> => {
    const url = `${appUserEndpoint}region/${regionId}`;
    const { data } = await axios.get(`${url}`);
    return Promise.resolve(data);
  },

  getAppUsers: async (params: { id: string }): Promise<IUser[]> => {
    const url = `${appUserEndpoint}getUsers`;
    const { data } = await axios.get(`${url}`, {params});
    return Promise.resolve(data);
  },

  getAccessLevels: async (): Promise<IAccessLevel[]> => {
    const url = `${identityEndpoint}accessLevel/`;
    const { data } = await axios.get(url);
    return data;
  },

  getAllUsers: async (): Promise<IUser[]> => {
    const url = `${Endpoints.getEndpoint("REACT_APP_IDENTITY_ENDPOINT")}user/all`;
    const { data } = await axios.post(url);
    return data;
  },

  getUserById: async (userId: string): Promise<{ id: string, code: string }> => {
    const url = `${Endpoints.getEndpoint("REACT_APP_IDENTITY_ENDPOINT")}user/${userId}`;
    const { data } = await axios.get(url);
    return data;
  },

  getCustomers: async (criteria: string): Promise<ICustomer[]> => {
    const params = {
      q: criteria || ''
    };
    const url = `${Endpoints.getEndpoint("REACT_APP_IDENTITY_ENDPOINT")}customers`;
    const { data } = await axios.get(url, { params });
    return data;
  },

  createUser: async (user: IUser): Promise<void> => {
    const { data } = await axios.post(`${identityEndpoint}signup`, user);
    return Promise.resolve(data);
  },

  saveUser: async (user: IUser): Promise<void> => {
    return axios.put(`${userEndpoint}`, user);
  },

  updateUserFields: async (userId: string, fields: Object): Promise<void> => {
    return axios.put(`${userEndpoint}update/${userId}`, fields);
  },

  deleteUser: async (user: IUser): Promise<void> => {
    return axios.delete(`${userEndpoint}${user.id}/${user.tenantId}`,);
  },

  blockUser: async (userName: string): Promise<any> => {
    const payload = JSON.stringify({
      login: userName
    })
    const message = EncryptHelper.encrypt(payload);
    return axios.put(`${userEndpoint}block`, { message });
  },

  activateUser: async (userId: string): Promise<void> => {
    return axios.post(`${identityEndpoint}signup/activate/${userId}`);
  },

  getUserPasswordValidations: async (userId: string): Promise<IUserPasswordValidationsResponse> => {
    const encryptedUserId = EncryptHelper.encrypt(userId)
    const { data } = await axios.get(`${userEndpoint}passwordValidations/${encryptedUserId}`);
    return data;
  },

  getUserPreferences: async (userId: string): Promise<any[]> => {
    const { data } = await axios.post(`${userPreferencesEndpoint}`,{ params: {"id": userId}});
    return data;
  },

  updateUserPreferences: async (userId: string, preferences: Object): Promise<void> => {
    return axios.put(`${userPreferencesEndpoint}`, preferences, {
      headers: {
        "userid": userId
      }
    });
  }

} as IUsersService;
 